import gql from "graphql-tag";

const _props = `
    ExclusionDays
    ExclusionSpans {
        from
        to
    }
    ExclusionWeekDays
    ExclusionPeriods
`;

export const ExclusionPeriods = {
    Queries: {
        ExclusionPeriods: gql`
        query exclusionPeriods($voucherId: ID, $from: Date, $to: Date) {
            exclusionPeriods(voucherId: $voucherId, from: $from, to: $to) {
                ${_props}
            }
        }`,
    }
};
