<template lang="pug">
.row
  .col-12.mt-5.text-center
    img(:src="`${publicPath}images/touribook-branding-150x150.png`", style="height: 120px")
    h2.mt-3 {{ $t("booking-confirmed.headline") }}
    h4 {{ voucher.BookedAt | formatDate }} {{ $t("constants.until") }} {{ moment(voucher.BookedAt).add(product.Uebernachtungen, "days").toDate() | formatDate }}

  .col-12.mt-3(v-if="currentVouchers && Array.isArray(currentVouchers) && currentVouchers.length")
    voucher-cards.mt-3(
      :vouchers="currentVouchers"
      :remove-voucher="removeVoucher"
    )

  .col-12.mt-3
    template(v-if="bookingRequest")
      p(v-if="bookingRequest.Title == 'Herr'") {{ $t("constants.AddressM") }} {{ bookingRequest.LastName }},
      p(v-else) {{ $t("constants.AddressF") }} {{ bookingRequest.LastName }},
    p {{ $t("booking-confirmed.text1") }}
      b  {{ voucher.BookedAt | formatDate }} {{ $t("constants.until") }} {{ moment(voucher.BookedAt).add(product.Uebernachtungen, "days").toDate() | formatDate  }}.
    p {{ $t("booking-confirmed.text2") }}

    p(v-if="parentVoucher") {{ $t("constants.mainVoucher") }}:&nbsp;
      b {{ parentVoucher.Id }}

    h6.mt-4 {{ $t("booking-confirmed.yourHost") }}:
    p {{ host.Match1 }}
      br
      | {{ host.PAdresse4 }}
      br
      | {{ host.PPLZ }} {{ host.PORT }}
    p
      font-awesome-icon.mr-2(:icon="['fas', 'phone-alt']")
      span {{ host.TELEFON1 }}
      br
      font-awesome-icon.mr-2(:icon="['fas', 'envelope']")
      a(:href="'mailto:' + host.EMAIL1") {{ host.EMAIL1 }}
    p.mt-4 {{ $t("booking-confirmed.text3") }}
    p(v-if="parentVoucher") {{ $t("constants.mainVoucherInfo").toString().replace("voucherId", parentVoucher.Id) }}
    p.mb-0 {{ $t("constants.closing") }}
    img(:src='$t("constants.touriBookTeam", {publicPath: publicPath})', style="height: 30px").mb-5

  .col-12.mt-3(v-if="!bookingTicket.DatesCancelRequestedAt && !bookingTicket.ThirdPartyBookingId")
    .float-right
      button.button.button-secondary.button-td-grey(
        v-if="!parentVoucher"
        @click="requestCancelBookingTicket"
        :disabled="isDisabled"
        :class="{ 'disabled': isDisabled }"
      ) Stornierung anfragen

</template>

<script>
import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";
import { BookingDateRequestStatusEnum } from "@/utils/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum";
import VoucherGroupBookingsComponent from "@/components/voucherGroupBookings/VoucherGroupBookingsComponent";
import EventBus from "@/event-bus";
import { VoucherGroupBookingStatusEnum } from "@/utils/enums/voucherGroupBookingStatus/VoucherGroupBookingStatusEnum.ts";
import VoucherCards from "@/views/partials/vouchers/VoucherCards.vue";
const moment = require("moment");

export default {
  name: "TouribookBookingConfirmed",
  components: { VoucherCards },
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    parentVoucher: {
      type: Object,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    bookingDateRequests: {
      type: Array,
      required: true,
    },
    loadVoucher: {
      type: Function,
      required: true,
    },
    vouchers: {
      type: Array,
      required: false,
    },
    loadVouchers: {
      type: Function,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    saveBookingRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      moment: moment,
      ChannelPropertyTypesEnum: ChannelPropertyTypesEnum,
    };
  },
  methods: {
    async requestCancelBookingTicket() {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie die Anfrage für die Stornierung Ihrer Buchung absenden wollen?"
        ).then((e) => e);
        if (!confirmed) {
          return;
        }
        this.bookingDateRequests
          .filter(
            (b) =>
              b.BookingDateRequestStatusId ==
              BookingDateRequestStatusEnum.DateBooked
          )
          .forEach(
            (b) =>
              (b.BookingDateRequestStatusId =
                BookingDateRequestStatusEnum.DateCancelRequested)
          );
        EventBus.$emit("changeLoadingState", true);
        const savedBookingRequest = await this.saveBookingRequest(
          this.bookingRequest,
          this.vouchers,
          this.bookingTicket,
          this.bookingDateRequests
        );
        EventBus.$emit("changeLoadingState", false);
        if (savedBookingRequest.Message) {
          this.$alert(savedBookingRequest.Message);
        }
        if (savedBookingRequest.IsSuccess) {
          this.loadVoucher();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async removeVoucher(voucher) {
      try {
        if (!voucher) {
          return;
        }
        const voucherGroupBookingsComponent =
          new VoucherGroupBookingsComponent();
        EventBus.$emit("changeLoadingState", true);
        const voucherGroupBooking =
          await voucherGroupBookingsComponent.getVoucherGroupBooking(
            this.$apollo,
            this.bookingRequest.Id,
            voucher.Id
          );
        EventBus.$emit("changeLoadingState", false);
        if (!voucherGroupBooking) {
          return this.$alert(
            `Keine Gutscheingruppenanfrage für Gutschein ${voucher.Id} gefunden.`
          );
        }
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie die Gutscheingruppenanfrage für das Löschen anfragen möchten?"
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        EventBus.$emit("changeLoadingState", true);
        voucherGroupBooking.VoucherGroupBookingStatusId =
          VoucherGroupBookingStatusEnum.CustomerDeletionRequested;
        const savedVoucherGroupBooking =
          await voucherGroupBookingsComponent.saveVoucherGroupBooking(
            this.$apollo,
            voucherGroupBooking
          );
        EventBus.$emit("changeLoadingState", false);
        if (!savedVoucherGroupBooking) {
          return this.$alert(
            "Die Gutscheingruppenanfrage konnte nicht gespeichert werden."
          );
        }
        if (savedVoucherGroupBooking.Message) {
          this.$alert(savedVoucherGroupBooking.Message);
        }
        if (savedVoucherGroupBooking.IsSuccess) {
          this.loadVouchers();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    currentVouchers() {
      try {
        if (
          this.vouchers &&
          Array.isArray(this.vouchers) &&
          this.vouchers.length
        ) {
          return this.vouchers;
        }
        if (this.parentVoucher) {
          return [this.voucher];
        }
        return undefined;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
